import { FormProvider, set, useForm, useFormContext } from "react-hook-form";
import "../../styles/auctionFormStyles.css";
import DateRangePicker from "./DatePicker";
import ImageUpload from "./ImageUpload";
import Tags from "./Tags";
import {
  descriptionEnglish,
  descriptionItalian,
  reservePrice,
  startingPrice,
  titleInput,
  titleInputItalian,
} from "../../utils/inputValidations";
import { useEffect, useState } from "react";
// import { auth, db } from "../../config/firebaseSetup";
// import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { toast } from "react-toastify";
import Loading from "../Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../../config/firebaseSetup";
import CategoriesSelections from "./CategoriesSelections";
import { deleteObject, getStorage, ref } from "firebase/storage";
import { useTranslation } from "react-i18next";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TextEditor from "../TextEditor";

export const renderInput = (
  { name, label, type, id, validationRules, placeholder, multiline, icon },
  methods,
  t
) => {
  const { register, formState } = methods;
  const inputErrors = formState.errors[name] || [];
  // const isInvalid = inputErrors.length > 0;
  // console.log(inputErrors);
  // console.log(icon);
  return (
    <div className="auction-form-input">
      <label htmlFor={name}>{t(`${name}`)}</label>
      {multiline ? (
        <textarea
          type={type}
          id={name}
          name={name}
          placeholder={t(placeholder, { ns: "placeholder" })}
          {...register(name, validationRules)}
        ></textarea>
      ) : (
        <div className="icon-input-contain">
          {/* {icon && (
            <div className="iicd">
              {icon && (
                <img
                  src={icon}
                  alt=""
                  className="icon-input-c"
                  width={15}
                  height={15}
                />
              )}
              {/* <FontAwesomeIcon icon={icon} className="icon-input-c" /> 
            </div>
          )} */}
          <input
            type={type}
            id={name}
            name={name}
            // style={{ paddingLeft: `${icon ? "3rem" : ""}` }}
            placeholder={t(placeholder, { ns: "placeholder" })}
            {...register(name, validationRules)}
          />
        </div>
      )}
      {inputErrors.message && (
        <div className="inputErrors">
          {t(inputErrors?.message, { ns: "errors" })}
        </div>
      )}
    </div>
  );
};

const AuctionForm = ({
  defaultValues,
  onCancel,
  handleSubmit,
  showLoader,
  updateDraft,
  setShowLoader,
  handleDraft,
  duplicate,
  showDraftEdit,
  auctionStatus = "",
  edit = false,
}) => {
  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });
  const [selectedfile, setSelectedFile] = useState(defaultValues.images || []);
  const [tags, setTags] = useState(defaultValues.tags || []);
  const { t } = useTranslation(["auctionForm", "errors", "placeholder"]);

  useEffect(() => {
    const handleWheel = (e) => {
      if (e.target.type === "number") {
        e.preventDefault();
      }
    };

    document.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      document.removeEventListener("wheel", handleWheel);
    };
  }, []);

  const submitHandler = methods.handleSubmit(async (formData) => {
    const missingFields = [];
    // Check for missing tags
    if (tags.length === 0) missingFields.push("Tags");
    if (selectedfile.length === 0) missingFields.push("Images");

    if (missingFields.length > 0) {
      toast.error(`Please complete the following fields: ${missingFields.join(", ")}`);
      return;
    }
    // Proceed with submission if all fields are complete
    setShowLoader(true);
      await handleSubmit(
        formData,
        methods,
        setSelectedFile,
        setTags,
        handleCancelDelete
      );
  });

  const saveAsDraft = async () => {
    if (!handleDraft) return;
    const draftData = methods.getValues();
    setShowLoader(true);
    await handleDraft(draftData);
    setShowLoader(false);
    methods.reset();

    onCancel();
  };

  // console.log(selectedfile);
  const handleCancelDelete = () => {
    if (edit) return;
    const storage = getStorage();
    try {
      selectedfile.map(async (file) => {
        // console.log(file.bucket);
        const imgRef = ref(storage, `${file.bucket}/${file.filename}`);
        await deleteObject(imgRef);
      });
    } catch (error) {
      console.log(error);
      return;
    }
  };

  // console.log(updateDraft);
  const actionsButtons = () => {
    const isFormValid = methods.formState.isValid && tags.length > 0 && selectedfile.length > 0;

    if (edit) {
      if (auctionStatus === "draft") {
        return (
          <>
            <button
              onClick={() => updateDraft(methods.getValues())}
              className="draft-auction"
            >
              Update Draft
            </button>
            <button
              onClick={submitHandler}
              className="submit-auction"
              disabled={!isFormValid}
            >
              Save And Send For Approval
            </button>
          </>
        );
      }
      return (
        <>
          <button
            onClick={submitHandler}
            className="submit-auction"
            disabled={!isFormValid}
          >
            Save And Send For Approval
          </button>
        </>
      );
    }
    if (duplicate) {
      if (auctionStatus === "pending") {
        return (
          <>
            <button onClick={saveAsDraft} className="draft-auction">
              Duplicate As Draft
            </button>
            <button
              onClick={submitHandler}
              className="submit-auction"
              disabled={!isFormValid}
            >
              Copy And Send For Approval
            </button>
          </>
        );
      }
      if (auctionStatus === "draft") {
        return (
          <>
            {/* <button
              onClick={() => {
                updateDraft(methods.getValues());
              }}
              className="draft-auction"
            >
              Update Draft
            </button> */}
            <button onClick={saveAsDraft} className="draft-auction">
              Duplicate As Draft
            </button>
            <button
              onClick={submitHandler}
              className="submit-auction"
              disabled={!isFormValid}
            >
              Send For Approval
            </button>
          </>
        );
      }
    }

    return (
      <>
        <button onClick={saveAsDraft} className="draft-auction">
          Save As Draft
        </button>
        <button
          onClick={submitHandler}
          className="submit-auction"
          disabled={!isFormValid}
        >
          Send For Approval
        </button>
      </>
    );
  };

  return (
    <FormProvider {...methods}>
      <form className="auction-from" onSubmit={(e) => e.preventDefault()}>
        {renderInput(titleInput, methods, t)}
        <div className="editor-container-af">
          <label>Description English</label>
          <TextEditor
            name="descriptionEnglish"
            defaultValue={defaultValues.descriptionEnglish}
            error={methods.formState.errors.descriptionEnglish?.message}
          />
        </div>
        {/* {renderInput(descriptionEnglish, methods, t)} */}
        {renderInput(titleInputItalian, methods, t)}
        {/* {renderInput(descriptionItalian, methods, t)} */}
        <div className="editor-container-af">
          <label>Description Italian</label>
          <TextEditor
            name="descriptionItalian"
            defaultValue={defaultValues.descriptionItalian}
            error={methods.formState.errors.descriptionItalian?.message}
          />
        </div>
        {renderInput(startingPrice, methods, t)}
        {renderInput(reservePrice, methods, t)}
        <Tags tags={tags} setTags={setTags} clearErrors={methods.clearErrors} />
        <CategoriesSelections
          defaultValues={{
            subcategory: defaultValues.subcategory,
            category: defaultValues.category,
          }}
        />
        <DateRangePicker
          defaultStartDate={defaultValues.startDate}
          defaultEndDate={defaultValues.endDate}
        />
        <DndProvider backend={HTML5Backend}>
          <ImageUpload
            selectedfile={selectedfile}
            setSelectedFile={setSelectedFile}
            clearErrors={methods.clearErrors}
            setShowLoader={setShowLoader}
            duplicate={duplicate}
          />
        </DndProvider>
        <div className="auction-form-buttons">
          {actionsButtons()}
          <button
            className="auction-cancel"
            onClick={() => {
              onCancel();
              handleCancelDelete();
            }}
          >
            Cancel
          </button>
        </div>
      </form>
      {showLoader && <Loading />}
    </FormProvider>
  );
};

export default AuctionForm;
