import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useFormContext } from "react-hook-form";
import "../styles/TextEditor.css";

const TextEditor = ({ name, defaultValue, error }) => {
  const [value, setValue] = useState(defaultValue || "");
  const { register } = useFormContext();

  useEffect(() => {
    register(name, {
      required: "Description is required",
      validate: (value) => {
        const div = document.createElement("div");
        div.innerHTML = value || "";
        const text = div.textContent || div.innerText || "";
        return text.trim().length > 0 || "Description cannot be empty";
      },
    });
  }, [register, name]);

  const handleChange = (content) => {
    setValue(content);
    const event = {
      target: {
        name,
        value: content,
      },
    };
    register(name).onChange(event);
  };

  return (
    <div className="quill-editor">
      <ReactQuill
        theme="snow"
        modules={{
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["clean"],
          ],
        }}
        value={value}
        onChange={handleChange}
      />
      {error && <div className="inputErrors">{error}</div>}
    </div>
  );
};

export default TextEditor;
