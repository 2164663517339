// import { faEuro } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { auth } from "../config/firebaseSetup";
import { useEffect, useState } from "react";
import euro from "../assets/icons/euro.svg";
import ReservePrice from "./ReservePrice";
import i18n from "../i18n/i18n";
import slugify from "slugify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

const AuctionItem = ({ auction, id }) => {
  const targetDate = new Date(auction?.serverEndTimeForAuction?.toMillis());
  const startDate = new Date(auction?.serverStartTimeForAuction?.toMillis());
  const currentDate = new Date().getTime();
  const [languageVersion, setLanguageVersion] = useState(0);
  const currentLanguage = i18n.language;

  let timeDifference;
  let timeLeftMessage;
  let closingSoonMessage = "";
  let closedMessage = "";

  if (currentDate < startDate) {
    // Auction hasn't started yet
    timeDifference = startDate - currentDate;
    timeLeftMessage = "Coming Soon";
  } else if (currentDate >= targetDate) {
    // Auction has ended
    timeLeftMessage = "Auction Ended";
    closedMessage = "Action Closed";
  } else {
    // Auction has started but not ended
    timeDifference = targetDate - currentDate;
    const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hoursLeft = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutesLeft = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );

    if (daysLeft === 0) {
      if (hoursLeft === 0 && minutesLeft < 60) {
        timeLeftMessage = `${minutesLeft} Mins left`;
        closingSoonMessage = "Closing Soon";
      } else if (hoursLeft < 24 || (hoursLeft === 24 && minutesLeft === 0)) {
        timeLeftMessage = `${hoursLeft} Hours left`;
        if (hoursLeft < 48) {
          closingSoonMessage = "Closing Today";
        }
      } else {
        timeLeftMessage = `${hoursLeft} Hours left`;
      }
    } else if (daysLeft === 1 && hoursLeft <= 23 && minutesLeft <= 59) {
      timeLeftMessage = "1 Day left";
      closingSoonMessage = "Closing Soon";
    } else {
      timeLeftMessage = `${daysLeft} Days left`;
    }
  }

  const navigate = useNavigate();

  const user = auth.currentUser;
  const userHighestBid = auction?.bidHistory
    ?.filter((bid) => bid.userId === user?.uid)
    .reduce(
      (maxBid, currentBid) =>
        parseInt(currentBid.bidAmount, 10) > parseInt(maxBid.bidAmount, 10)
          ? currentBid
          : maxBid,
      { bidAmount: "0" }
    );

  const shouldRenderBid = userHighestBid && userHighestBid.bidAmount !== "0";

  const handleClick = () => {
    const title =
      currentLanguage === "it"
        ? auction.auctionTitleItalian
        : auction.auctionTitleItalian;

    // Replace spaces and special characters with hyphens (-)
    const formattedTitle = title?.replace(/\s+/g, "-").replace(/\W/g, "-");

    // Generate a slug from the formatted title, replacing special characters with hyphens

    const slug = slugify(formattedTitle, {
      lower: true,
      remove: /[$*_+~.()'"!\-:@]/g,
    });
    const encodedTitle = encodeURIComponent(slug);
    const encodedId = encodeURIComponent(id);

    if (timeLeftMessage === "Auction Ended") {
      toast.error("This auction has ended!");
    }
    navigate(`/product/${currentLanguage}/${encodedTitle}/${encodedId}`);
  };

  useEffect(() => {
    const handleLanguageChange = () => {
      // Increment a counter to trigger rerender
      setLanguageVersion((langVersion) => langVersion + 1);
    };

    // Subscribe to the language change event
    i18n.on("languageChanged", handleLanguageChange);

    // Cleanup the event listener on component unmount
    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]);

  const getCurrentPrice = () => {
    if (!auction.bidHistory || auction.bidHistory.length === 0) {
      return Number(auction.auctionStartingPrice);
    }
    return Number(auction.highestBid);
  };

  return (
    <motion.div
      className="auctionItem-card"
      // onClick={() => navigate(`/auction-item/${id}`)}
      // onClick={handleClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 3 }}
      onClick={handleClick}
    >
      {auction?.likedBy && auction.likedBy.length > 0 && (
        <div className="likedCountContainer">
          <FontAwesomeIcon icon={faHeart} color="yellow" />
          <h2>{auction.likedBy.length}</h2>
        </div>
      )}

      <div className="img-contain-height">
        {/* <div
          className="bgImgAuctionItem"
          style={{
            backgroundImage: `url( ${
              auction.images ? auction.images[0].fileimage : ""
            } )`,
          }}
        ></div> */}
        <img
          src={auction?.images[0].fileimage}
          alt="Auction Item"
          // style={{ width: "100%" }}
        />
      </div>

      {closingSoonMessage && (
        <div
          className={`closingMessage ${
            closingSoonMessage === "Closing Today" ? "closingToday" : ""
          }`}
        >
          {closingSoonMessage}
        </div>
      )}

      {closedMessage && (
        <div
          className={`closingMessage ${
            closedMessage === "Auction Closed" ? "closingToday" : ""
          }`}
        >
          {closedMessage}
        </div>
      )}

      <div className="auctionItem-content">
        <h2>
          {currentLanguage === "it" ? (
            <>
              {auction.auctionTitleItalian}{" "}
              <sub style={{ fontSize: "10px", marginLeft: "10px" }}>
                ({auction.tag})
              </sub>
            </>
          ) : (
            <>
              {auction.auctionTitle}{" "}
              <sub style={{ fontSize: "10px", marginLeft: "10px" }}>
                ({auction.tag})
              </sub>
            </>
          )}
        </h2>

        <div className="auctionItem-dContainer">
          <div className="auctionItem-details">
            <div className="price-container">
              {auction?.highestBid > 0 ? (
                <>
                  <h3
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                    }}
                  >
                    Last Bid{" "}
                    <img src={euro} alt="" width={"20px"} height={"20px"} />
                    <span className="text-overflow-hidden">
                      {auction?.highestBid}
                    </span>
                  </h3>
                  {shouldRenderBid && (
                    <h3
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                      }}
                    >
                      My Bid{" "}
                      <img src={euro} alt="" width={"20px"} height={"20px"} />
                      <span className="text-overflow-hidden">
                        {userHighestBid.bidAmount}
                      </span>
                    </h3>
                  )}
                </>
              ) : (
                <h3>No Bids</h3>
              )}
              <span className="price-label">
                {auction.bidHistory?.length > 0
                  ? "Current Bid"
                  : "Starting Price"}
              </span>
              <div className="price-value">
                <img src={euro} alt="euro" width="20px" height="20px" />
                <span>{getCurrentPrice()}</span>
              </div>
            </div>
          </div>

          <ReservePrice auction={auction} />
          {/* <div
            className="reservePrice"
            style={{
              marginTop: "1rem",
              color:
                auction?.highestBid > auction.auctionReservePrice
                  ? "green"
                  : "red",
            }}
          >
            {auction?.highestBid > auction.auctionReservePrice
              ? "Reserve Price Met"
              : "Reserve Price Not Met"}
          </div> */}
          <div className="auctionItem-details">
            <p className="title">Time Left</p>
            <p
              style={{
                color:
                  closingSoonMessage === "Closing Today" ||
                  timeLeftMessage === "Auction Ended" ||
                  timeLeftMessage === "1 Day left" ||
                  timeLeftMessage.includes("Mins left")
                    ? "#e70e02"
                    : "",
              }}
              className="auctionItem-daysLeft"
            >
              {timeLeftMessage}
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default AuctionItem;
