import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../config/firebaseSetup";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { FIREBASE_URL } from "../config/urlConfig";

// get email From firebase and make it ready for user
const fetchAndSendEmailTemplate = async (
  label,
  user,
  route,
  link = null,
  metaData = null,
  actionImage = null
) => {
  try {
    const emailsColRef = collection(db, "emailNotifications");
    const q = query(emailsColRef, where("label", "==", label));
    const userDocRef = doc(db, "users", user.uid);
    const querySnapshot = await getDocs(q);
    const userDocSnap = await getDoc(userDocRef);
    const userData = userDocSnap.data();

    if (!querySnapshot.empty) {
      querySnapshot.forEach(async (item) => {
        const emailTemplate = item.data();
        const currentDate = new Date();
        const loginDateTime = currentDate.toLocaleString();
        let recipientName =
          user?.firstName && user?.lastName
            ? user?.firstName + " " + user?.lastName
            : user?.nickname
            ? user?.nickname
            : user.email;

        const emailTemplateToUse =
          userData?.nationality?.id === "IT"
            ? emailTemplate.emailItalian
            : emailTemplate.emailEnglish;

        const emailSubjectToUse =
          userData?.nationality?.id === "IT"
            ? emailTemplate.subjectItalian
            : emailTemplate.subjectEnglish;

        // Construct emailBody using placeholders in the email template
        const emailBody = constructEmailBody(
          emailTemplateToUse,
          recipientName,
          "BID AND GO",
          loginDateTime,
          "BIDANDGO",
          "SUPPORT",
          "BIDS",
          link,
          actionImage
        );
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const notifications = userData.notifications || []; // If notifications field doesn't exist, create an empty array

          // Add the new notification to the beginning of the array (to display it first)
          const auctionData = metaData ? metaData : {};
          const newNotification = {
            message: emailTemplate.userProfileMsg || "Auction notification",
            timestamp: new Date(),
            read: false,
            id: uuidv4(),
            auctionData: auctionData || {},
          };
          notifications.unshift(newNotification);

          // Update the user document with the new notifications array
          await updateDoc(userDocRef, {
            notifications: notifications,
          });

          await updateAdminNotification(
            userData.uid,
            userData.email,
            newNotification
          );

          // Also send sms to user
          // TODO: SMS SENDING HERE
          if (userData.emailNotifications) {
            // Send email via backend
            try {
              await axios.post(route, {
                emailBody,
                emailTo: user.email,
                emailSubject: emailSubjectToUse,
              });
            } catch (error) {
              console.log(error);
              return;
            }
          }
          if (userData.phoneNotifications && userData.phoneNumber) {
            fetchAndSendSMSTemplate(
              label,
              user,
              `${FIREBASE_URL}/smsSend`,
              userData.phoneNumber
            );
          }
        } else {
          console.log("error");
        }
      });
    } else {
      console.error(`No email template found with label '${label}'`);
    }
  } catch (error) {
    console.error("Error fetching email template:", error);
  }
};

const updateAdminNotification = async (
  userId,
  userEmail,
  notificationMessage
) => {
  try {
    const adminNotificationsColRef = collection(db, "adminNotifications");
    const timestamp = new Date();

    // Add a new document to the "adminNotifications" collection
    await addDoc(adminNotificationsColRef, {
      userId: userId,
      userEmail,
      notificationMessage,
      timestamp: timestamp,
    });
  } catch (error) {
    return;
  }
};

const constructEmailBody = (
  emailTemplate,
  recipientName,
  appName,
  loginDateTime,
  senderName,
  senderPosition,
  companyName,
  link,
  auctionImage
) => {
  // Define placeholders
  const placeholders = {
    "{{recipientName}}": recipientName,
    "{{appName}}": appName,
    "{{loginDateTime}}": loginDateTime,
    "{{senderName}}": senderName,
    "{{senderPosition}}": senderPosition,
    "{{companyName}}": companyName,
    "{{link}}": link,
    "{{auctionImage}}": auctionImage,
  };

  // Regular expression to match placeholders in the email template
  const placeholderRegex = new RegExp(Object.keys(placeholders).join("|"), "g");

  // Replace placeholders with actual values
  const emailBody = emailTemplate.replace(placeholderRegex, (match) => {
    return placeholders[match];
  });

  return emailBody;
};

// get email From firebase and make it ready for user
const fetchAndSendSMSTemplate = async (label, user, route, number) => {
  try {
    const smsColRef = collection(db, "smsNotifications");
    const q = query(smsColRef, where("label", "==", label));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      querySnapshot.forEach(async (doc) => {
        const smsTemplate = doc.data();
        // Construct smsBody using placeholders in the sms template
        const smsBody = constructSMSBody(smsTemplate.smsEnglish, user.email);
        // console.log(smsBody);
        // Send sks via backend
        try {
          await axios.post(route, {
            messageBody: smsBody,
            userNumber: number,
            // userNumber: '923410040209',
          });
        } catch (error) {
          console.log(error);
        }
      });
    } else {
      console.error(`No sms template found with label '${label}'`);
    }
  } catch (error) {
    console.error("Error fetching sms template:", error);
  }
};

// Construct SMS body using placeholders
const constructSMSBody = (smsTemplate, recipientName) => {
  return smsTemplate.replace("{{recipientName}}", recipientName);
};

export default fetchAndSendEmailTemplate;
