import React from "react";
import {
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Home from "./publicRoutes/Home";
import Profile from "./privateRoutes/Profile";
import Tag from "./publicRoutes/Tag";
import AuctionDetail from "./publicRoutes/AuctionDetail";
import Contact from "./publicRoutes/Contact";
import { AnimatePresence } from "framer-motion";
import PrivacyPolicy from "./publicRoutes/PrivacyPolicy";
import TermsofService from "./publicRoutes/TermsofService";
import Footer from "../components/Footer";
import SuccessPage from "../pages/SuccessPage";
import PageNotFound from "../pages/PageNotFound";
import ResetPassword from "../pages/ResetPassword";
import ConversationView from "../components/messagingSystem/ConversationView";
import PayNowPage from "../pages/PayNowPage";
import AuctionItemDetailTestPage from "../pages/AuctionItemDetailTestPage";
import { MantineProvider } from '@mantine/core';
import DataDeletion from "./publicRoutes/DataDeletion";

const ProtectedRoute = ({ isAllowed, redirectPath = "/", children }) => {
  const location = useLocation();

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace state={{ from: location }} />;
  }

  return children ? children : <Outlet />;
};

const AppRouter = ({ user }) => {
  const location = useLocation();

  return (
    <Routes location={location} key={location.pathname}>
      <Route path="/" exact element={<Home />} />
      <Route path="/auctions" exact element={<Tag />} />
      <Route path="/contactUs" exact element={<Contact />} />
      <Route
        path="/product/:lang/:title/:urlId"
        exact
        element={<AuctionDetail />}
      />
      <Route
        path="/auction-detail-test-page"
        exact
        element={
          <MantineProvider withGlobalStyles withNormalizeCSS>
            <AuctionItemDetailTestPage />
          </MantineProvider>
        }
      />
      <Route
        path="/category/:category/subCategory/:subCategory"
        exact
        element={<Tag />}
      />
      {/* <Route element={<ProtectedRoute isAllowed={!!user} />}> */}
      <Route path="/profile" element={<Profile />} />
      <Route
        path="/privacy-policy"
        element={
          <>
            <PrivacyPolicy />
            <Footer color={"black"} />
          </>
        }
      />
      <Route
        path="/data-deletion"
        element={
          <>
            <DataDeletion />
            <Footer color={"black"} />
          </>
        }
      />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path={`/conversation/:conversationId`}
        element={<ConversationView />}
      />
      <Route path={`/payNow/:token`} element={<PayNowPage />} />
      <Route
        path="/term-of-service"
        element={
          <>
            <TermsofService />
            <Footer color={"black"} />
          </>
        }
      />
      {/* <Route path="/onboarding-seller/success" element={<SuccessPage />} /> */}
      {/* </Route> */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AppRouter;
