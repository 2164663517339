import ReactDOM from "react-dom";
import "../styles/bidsModalStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import euro from "../assets/icons/euro.svg";

const BidsModal = ({ bids, onClose }) => {
  console.log("bids prop:", bids);

  return ReactDOM.createPortal(
    <motion.div
      className="bidModal-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className="heading-biider">
        <h2>Bids History</h2>
        <button className="close-btn" onClick={onClose}>
          <FontAwesomeIcon icon={faClose} />
        </button>
      </div>
      {!bids || bids.length === 0 ? (
        <h2>No Bids Yet</h2>
      ) : (
        <>
          <div className="heading">
            <div className="heading-item">Bidders</div>
            <div className="heading-item">Amount</div>
            <div className="heading-item">Date</div>
          </div>
          <div>
            {bids
              .sort((a, b) => {
                const dateA = a.timestamp?.seconds
                  ? new Date(a.timestamp.seconds * 1000)
                  : new Date(a.timestamp);
                const dateB = b.timestamp?.seconds
                  ? new Date(b.timestamp.seconds * 1000)
                  : new Date(b.timestamp);
                return dateB - dateA;
              })
              .map((bid) => {
                console.log("bid timestamp:", bid.timestamp);
                return (
                  <div
                    className="bidders"
                    key={bid.id || `${bid.username}-${bid.bidAmount}-${bid.timestamp}`}
                  >
                    <div className="nationality bgi">
                      <span
                        style={{ fontSize: "30px" }}
                        className={`fi fi-${bid.nationality?.id?.toLowerCase()} fis`}
                      />
                      <h2>{bid.username}</h2>
                    </div>
                    <div className="bidder-ammount bgi">
                      <img src={euro} alt="" width={"20px"} height={"20px"} />
                      <h2>{bid.bidAmount}</h2>
                    </div>
                    <div className="bgi">{formatTimestamp(bid.timestamp)}</div>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </motion.div>,
    document.querySelector("#auctionDupFormModal")
  );
};

const formatTimestamp = (timestamp) => {
  let date;

  if (!timestamp) {
    return <div className="date-bider">No Date Available</div>;
  }

  if (timestamp?.seconds) {
    date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
  } else if (typeof timestamp === "string") {
    date = new Date(timestamp);
  } else {
    return <div className="date-bider">Invalid Date</div>;
  }

  if (isNaN(date.getTime())) {
    return <div className="date-bider">Invalid Date</div>;
  }

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return (
    <div className="date-bider">
      <div>{`${day}/${month}/${year}`}</div>
      <div>{`${hours}:${minutes}:${seconds}`}</div>
    </div>
  );
};

export default BidsModal;