import { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Countdown from "../components/CountDown";
import Button from "../components/Button";
import AuthBtn from "../components/AuthBtn";
import "../styles/auctionItemDetailsStyles.css";
import BidNowModal from "../components/BidNowModal";
import Loading from "../components/Loader";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../components/Modal";
import { Context as AuctionContext } from "../context/AuctionContext";
import Slider from "../components/slider/Slider";
import {
  faContactCard,
  faExclamationCircle,
  faExclamationTriangle,
  faHeart,
  faHeartCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import iconEuro from "../assets/icons/euro.svg";
import { Timestamp, doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { auth, db } from "../config/firebaseSetup";
import { toast } from "react-toastify";
import BidsModal from "../components/BidsModal";
import Overlay from "../components/Overlay";
import { useUser } from "../context/UserContext";
import ReportModal from "../components/ReportBidModal";
import ReportAuctionForm from "../components/ReportAuctionForm";
import { useTranslation } from "react-i18next";
import leftArrow from "../assets/icons/leftArrow.svg";
import Carousel from "../components/Carousel";
import { AnimatePresence, motion } from "framer-motion";
import { getMissingFields, hasCompletedInformation } from "../utils/checkuser";
import EditAuctionRequestForm from "../components/userAuctionsDashboard/EditAuctionRequestForm";
import euro from "../assets/icons/euro.svg";
import axios from "axios";
import fetchAndSendEmailTemplate from "../utils/emailSend";
import i18n from "../i18n/i18n";
import ReservePrice from "../components/ReservePrice";
import TagsColors from "../components/TagsColors";
import { onAuthStateChanged } from "firebase/auth";
import { FIREBASE_URL } from "../config/urlConfig";

const AuctionItemDetailPage = () => {
  const [showBidNow, setBidNow] = useState(false);
  const [userLiked, setUserLiked] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showBidsModal, setShowBidModal] = useState(false);
  const [showReportModal, setReportModal] = useState(false);
  const [showContactModal, setContactModal] = useState(false);
  const [showBidReportModal, setShowBidReportModal] = useState(false);
  const [showBidContactModal, setShowBidContactModal] = useState(false);
  const [optionalBidAmount, setOptionalBidAmount] = useState(0);
  const [bidAmount, setBidAmount] = useState(0);
  const scrollIntoView = useRef();
  const prevAuctionItemRef = useRef(null);
  const location = useLocation();
  const { showLoginModalHandler } = useUser();
  const user = auth.currentUser;
  const [auctionStarted, setAuctionStarted] = useState(false);
  const [startDatePassed, setStartDatePassed] = useState(false);
  const [showCompleteInfoModal, setShowCompleteInfoModal] = useState(false);
  const [userData, setUserData] = useState(null);
  const methods = useForm();
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [auctionItem, setAuctionItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userLanguage, setUserLanguage] = useState("");
  const { urlId } = useParams();
  const id = decodeURIComponent(urlId);
  const navigate = useNavigate();
  const { t } = useTranslation("auctionItem");

  useEffect(() => {
    const detectedLanguage = navigator.language.split("-")[0];
    setUserLanguage(detectedLanguage === "it" ? "it" : "en");
  }, []);

  useEffect(() => {
    setIsLoggedIn(!!user);
  }, [user]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(userDocRef);
        setUserData(docSnap.exists() ? docSnap.data() : {});
      } else {
        setIsLoggedIn(false);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    document.body.classList.toggle("body-overflow", showBidNow);
  }, [showBidNow]);

  useEffect(() => {
    if (auctionItem) {
      const auctionRef = doc(db, "Auction", id);
      getDoc(auctionRef).then((auctionDoc) => {
        const auctionData = auctionDoc.data();
        const currentBid = auctionData.highestBid
          ? Number(auctionData.highestBid)
          : Number(auctionData.auctionStartingPrice);
        setBidAmount(currentBid);
        if (user) {
          const bidHistory = auctionData.bidHistory || [];
          const userBid = bidHistory.find((bid) => bid.userId === user.uid);
          if (userBid) setOptionalBidAmount(userBid.optionalBidAmount);
        }
      });
    }
  }, [auctionItem, id, user]);

  useEffect(() => {
    const handleWheel = (e) => {
      if (e.target.type === "number") e.preventDefault();
    };
    document.addEventListener("wheel", handleWheel, { passive: false });
    return () => document.removeEventListener("wheel", handleWheel);
  }, []);

  const processBids = async (
    bidA,
    userId,
    userEmail,
    nationality,
    username,
    isAutoBid,
    optionalAmount = 0
  ) => {
    try {
      setShowLoader(true);
      const auctionRef = doc(db, "Auction", id);
      const auctionDoc = await getDoc(auctionRef);
      const auctionData = auctionDoc.data();
      const existingBidHistory = auctionData.bidHistory || [];

      if (auctionData.lastBidderId && auctionData.lastBidderId !== userId) {
        await addOutBidAuctionToUser(auctionData.lastBidderId, id);
      }

      const updatedBidHistory = existingBidHistory.map((bid) =>
        bid.userId === userId
          ? { ...bid, optionalBidAmount: Number.parseInt(optionalAmount) }
          : bid
      );

      const timestamp = new Date().toISOString();
      const newBid = {
        userId,
        bidAmount: Number.parseInt(bidA),
        optionalBidAmount: Number.parseInt(optionalAmount),
        timestamp,
        userEmail,
        nationality,
        username,
        userCommission: userData.commissionPercent,
      };

      updatedBidHistory.push(newBid);
      updatedBidHistory.sort((a, b) => b.bidAmount - a.bidAmount);

      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);
      const userDataDoc = userDoc.data();

      if (userDataDoc?.outBids?.includes(id)) {
        const updatedOutBids = userDataDoc.outBids.filter(
          (auction) => auction !== id
        );
        await updateDoc(userDocRef, { outBids: updatedOutBids });
      }

      await updateUserProfileWithBid(id);
      await updateAuctionData(auctionRef, bidA, updatedBidHistory, userEmail, userId);

      const newBidTimestamp = new Date().getTime();
      const fourMinutesInMilliseconds = 4 * 60 * 1000;
      const auctionEndTime = auctionItem.serverEndTimeForAuction.toMillis();

      if (newBidTimestamp >= auctionEndTime - fourMinutesInMilliseconds) {
        const newEndTime = new Date(auctionEndTime + fourMinutesInMilliseconds);
        await updateAuctionEndTime(auctionRef, newEndTime);
      }

      !isAutoBid && toast.success("Bid placed successfully!");
      setShowLoader(false);
      setBidNow(false);

      const metaData = {
        auctionId: id,
        auctionTitleEn: auctionData.auctionTitle,
        auctionTitleIt: auctionData.auctionTitleItalian,
      };

      await checkBidAndSendNotification(
        { ...userData, user },
        bidAmount,
        updatedBidHistory,
        auctionData.lastBidderEmail,
        auctionData.lastBidderId,
        metaData,
        auctionData?.images[0]?.fileimage
      );
    } catch (error) {
      setShowLoader(false);
      setBidNow(false);
      console.log(error);
      !isAutoBid && toast.error("An error occurred while processing the bid");
    }
  };

  const updateAuctionEndTime = async (auctionRef, newEndTime) => {
    try {
      await updateDoc(auctionRef, {
        serverEndTimeForAuction: Timestamp.fromDate(newEndTime),
        isTimeExtended: true,
      });
    } catch (error) {
      console.error("Error updating auction end time:", error);
    }
  };

  const updateAuctionData = async (auctionRef, bidA, updatedBidHistory, email, uid) => {
    const isReservePriceMet = bidA > auctionItem.auctionReservePrice;
    await updateDoc(auctionRef, {
      highestBid: Number.parseInt(bidA),
      lastBidderEmail: email,
      lastBidderId: uid,
      bidHistory: updatedBidHistory,
      isReservePriceMet,
    });
  };

  const updateUserProfileWithBid = async (auctionId) => {
    const userRef = doc(db, "users", user.uid);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const myBids = userDoc.data().myBids || [];
      if (!myBids.includes(auctionId)) {
        await updateDoc(userRef, { myBids: [...myBids, auctionId] });
      }
    }
  };

  const addOutBidAuctionToUser = async (userId, auctionId) => {
    try {
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);
      const outBids = userDoc.data()?.outBids || [];
      if (!outBids.includes(auctionId)) {
        await updateDoc(userDocRef, { outBids: [...outBids, auctionId] });
      }
    } catch (error) {}
  };

  const calculateNextMinimumBid = (currentBid) => {
    const bidRanges = [
      { min: 50, max: 99, increment: 5 },
      { min: 100, max: 199, increment: 10 },
      { min: 200, max: 499, increment: 20 },
      { min: 500, max: 1999, increment: 50 },
      { min: 2000, max: 2999, increment: 100 },
      { min: 3000, max: 9999, increment: 200 },
      { min: 10000, max: 49999, increment: 500 },
      { min: 50000, max: 99999, increment: 1000 },
      { min: 100000, max: 999999999, increment: 25000 },
    ];
    const range = bidRanges.find((r) => currentBid >= r.min && currentBid <= r.max);
    return range ? currentBid + range.increment : currentBid + 5;
  };

  const submitHandler = async (formData) => {
    try {
      setShowLoader(true);
      if (formData["bid-amount"] && formData["bid-amount-optional"]) {
        await processBids(
          formData["bid-amount"],
          user.uid,
          user.email,
          userData.nationality,
          userData.nickname,
          false,
          formData["bid-amount-optional"]
        );
      } else {
        await processBids(
          formData["bid-amount"],
          user.uid,
          user.email,
          userData.nationality,
          userData.nickname,
          false
        );
      }
    } catch (error) {
      console.error("Error ", error);
    }
  };

  const generateQuickBidValues = (startingBid) => {
    const bidRanges = [
      { min: 50, max: 99, fee: 5 },
      { min: 100, max: 199, fee: 10 },
      { min: 200, max: 499, fee: 20 },
      { min: 500, max: 1999, fee: 50 },
      { min: 2000, max: 2999, fee: 100 },
      { min: 3000, max: 9999, fee: 200 },
      { min: 10000, max: 49999, fee: 500 },
      { min: 50000, max: 99999, fee: 1000 },
      { min: 100000, max: Infinity, fee: 25000 },
    ];
    const currentRange = bidRanges.find((r) => startingBid >= r.min && startingBid <= r.max);
    if (!currentRange) return [];
    const increment = currentRange.fee;
    return Array.from({ length: 3 }, (_, i) => startingBid + increment * (i + 1));
  };

  let startingBid = 10;
  if (auctionItem?.highestBid) startingBid = parseInt(auctionItem.highestBid, 10);
  else if (auctionItem?.auctionStartingPrice) startingBid = parseInt(auctionItem.auctionStartingPrice, 10);
  const quickBids = generateQuickBidValues(startingBid);

  const likeAuction = async (userId, auctionId, email) => {
    const userRef = doc(db, "users", userId);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const likedAuctions = userDoc.data().likedAuctions || [];
      const auctionIndex = likedAuctions.indexOf(auctionId);
      if (auctionIndex === -1) {
        likedAuctions.push(auctionId);
        await updateDoc(userRef, { likedAuctions });
        toast.success("Auction Liked");
        setUserLiked(true);
      } else {
        likedAuctions.splice(auctionIndex, 1);
        await updateDoc(userRef, { likedAuctions });
        setUserLiked(false);
        toast.success("Auction Disliked");
      }
      const auctionDocRef = doc(db, "Auction", auctionId);
      const auctionDoc = await getDoc(auctionDocRef);
      if (auctionDoc.exists()) {
        let likedBy = auctionDoc.data().likedBy || [];
        likedBy = auctionIndex === -1
          ? [...likedBy, { uid: userId, email }]
          : likedBy.filter((u) => u.uid !== userId);
        await updateDoc(auctionDocRef, { likedBy });
      }
    }
  };

  const checkUserLiked = async (userId, auctionId) => {
    const userRef = doc(db, "users", userId);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists() && userDoc.data().likedAuctions?.includes(auctionId)) {
      setUserLiked(true);
    }
  };

  useEffect(() => {
    if (user && auctionItem) checkUserLiked(user.uid, auctionItem?.id);
  }, [auctionItem]);

  const updateTimeRemaining = () => {
    if (auctionItem?.serverEndTimeForAuction && auctionItem?.serverStartTimeForAuction) {
      const now = new Date().getTime();
      const startDate = auctionItem.serverStartTimeForAuction.toMillis();
      const endDate = auctionItem.serverEndTimeForAuction.toMillis();
      if (now < startDate) {
        setAuctionStarted(false);
        setStartDatePassed(false);
        setTimeRemaining(startDate - now);
      } else if (now >= startDate && now <= endDate) {
        setAuctionStarted(true);
        setStartDatePassed(true);
        setTimeRemaining(endDate - now);
      } else {
        setAuctionStarted(false);
        setStartDatePassed(true);
        setTimeRemaining(0);
      }
    }
  };

  useEffect(() => {
    updateTimeRemaining();
  }, [auctionItem]);

  useEffect(() => {
    const intervalId = setInterval(updateTimeRemaining, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const fetchAuctionItem = (id, setAuctionItem, setLoading) => {
    const auctionRef = doc(db, "Auction", id);
    const unsubscribe = onSnapshot(auctionRef, (auctionDoc) => {
      if (
        auctionDoc.exists() &&
        !["pending", "suspended", "trashed"].includes(auctionDoc.data().status)
      ) {
        setAuctionItem({ id: auctionDoc.id, ...auctionDoc.data() });
      } else {
        setAuctionItem(null);
      }
      setLoading(false);
    });
    return unsubscribe;
  };

  useEffect(() => {
    const unsub = fetchAuctionItem(id, setAuctionItem, setLoading);
    return () => unsub();
  }, [id]);

  const payNowHandler = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(`${FIREBASE_URL}/generatePaymentURL`, {
        auctionId: auctionItem.id,
        userId: userData.uid,
        userCommission: userData.commissionPercent,
      });
      setLoading(false);
      if (data.url) navigate(data.url);
    } catch (error) {
      setLoading(false);
      toast.error("Unable to process payment right now please try again later");
    }
  };

  const userHighestBid = auctionItem?.bidHistory
    ?.filter((bid) => bid.userId === user?.uid)
    .reduce((maxBid, currentBid) =>
      parseInt(currentBid.bidAmount, 10) > parseInt(maxBid.bidAmount, 10) ? currentBid : maxBid,
      { bidAmount: "0" }
    );

  const shouldRenderBid = userHighestBid && userHighestBid.bidAmount !== "0";

  if (loading) return <Loading />;
  if (!auctionItem) return <div className="item-error">Auction not found or error fetching data</div>;

  const containerVariants = { hidden: { opacity: 0, y: -20 }, visible: { opacity: 1, y: 0, transition: { duration: 0.5, when: "beforeChildren", staggerChildren: 0.1 } } };
  const childVariants = { hidden: { opacity: 0, y: -20 }, visible: { opacity: 1, y: 0 } };
  const currentLanguage = i18n.language;

  return (
    <>
      <motion.div variants={containerVariants} initial="hidden" animate="visible" exit="hidden">
        <div className="auctionItem-details-page">
          <motion.button variants={childVariants} whileHover={{ scale: 1.1 }} onClick={() => navigate(user ? "/profile" : "/")} className="back-btn">
            <img src={leftArrow} alt="" />
          </motion.button>
          <motion.h2 variants={childVariants} className="aidp-head">
            {userLanguage === "it" || currentLanguage === "it" ? (
              <>
                {auctionItem.auctionTitleItalian} <sub style={{ fontSize: "15px", marginLeft: "20px" }}>({auctionItem.tag})</sub>
              </>
            ) : (
              <>
                {auctionItem.auctionTitle} <sub style={{ fontSize: "15px", marginLeft: "20px" }}>({auctionItem.tag})</sub>
              </>
            )}
          </motion.h2>
          <div>
            <div className="auctionItem-details-page-container">
              <motion.div className="auctionItem-details-page-container-slider" variants={childVariants}>
                <Carousel images={auctionItem.images} />
              </motion.div>
              <motion.div variants={childVariants} className="w-50">
                {timeRemaining > 0 && auctionItem?.isTimeExtended && (
                  <div className="time-extended-warning"><span>TIME EXTENDED 4 MINS</span></div>
                )}
                {timeRemaining <= 0 ? (
                  <div className="ended-con">
                    <h2 className="ended-auc">{t("auctionEnded")}</h2>
                    {user && <button onClick={() => setShowBidModal(true)}><FontAwesomeIcon icon={faExclamationCircle} color="#e63946" /></button>}
                  </div>
                ) : auctionStarted ? (
                  <Countdown serverTime={auctionItem.serverEndTimeForAuction.toMillis()} />
                ) : (
                  <div>
                    <h3 style={{ textAlign: "center", width: "100%" }}>{t("auctionStartsIn")}</h3>
                    <Countdown serverTime={auctionItem.serverStartTimeForAuction.toMillis()} />
                  </div>
                )}
                <div className="auctionItem-details-page-bid-details">
                  {auctionStarted ? (
                    <div className="current-bidder">
                      <div>
                        {auctionItem?.highestBid > 0 ? (
                          <div className="no_of_bids">
                            <h3><span className="lst-bid">{t("lastBid")}</span> <span className="text-overflow-hidden"><img src={euro} alt="" width={"17px"} height={"17px"} />{auctionItem?.highestBid}</span></h3>
                            {user && <button onClick={() => setShowBidModal(true)}>{auctionItem?.bidHistory?.length} {t("bids")}</button>}
                          </div>
                        ) : (
                          <h3 className="no-bid-h3">{t("noBids")}</h3>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="quickBid">
                  {timeRemaining <= 0 || !auctionStarted ? null : (
                    <>
                      <h4>{t("quickBids")}</h4>
                      <ul className="ul">
                        {quickBids.map((bid, index) => (
                          <li onClick={() => setBidAmount(bid)} key={index}><img src={euro} alt="" width={"15px"} height={"15px"} /><span className="text-overflow-hidden">{bid}</span></li>
                        ))}
                      </ul>
                    </>
                  )}
                  {timeRemaining <= 0 || !auctionStarted ? null : (
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="input-fields">
                        <div className="input-field-containers">
                          <img src={iconEuro} alt="icon" width={30} />
                          <input
                            type="number"
                            name="quickBidValue"
                            id={id}
                            placeholder="1000"
                            autoComplete="off"
                            min={calculateNextMinimumBid(Number(auctionItem.highestBid) || Number(auctionItem.auctionStartingPrice))}
                            value={bidAmount}
                            onChange={(e) => setBidAmount(e.target.value)}
                          />
                        </div>
                      </div>
                    </form>
                  )}
                  <ReservePrice auction={auctionItem} />
                  {user ? (
                    <div className="btn-container">
                      {timeRemaining <= 0 || !auctionStarted || user.uid === auctionItem.uid ? null : (
                        <Button
                          className="auctionItem-details-page-btn"
                          onClick={() => checkUserFieldsCompleted(userData, setShowCompleteInfoModal, setBidNow)}
                        >
                          {showBidNow || showCompleteInfoModal ? (
                            <div className="spinner_containers" aria-label="Loading..."><i className="spinner_item"></i><i className="spinner_item"></i></div>
                          ) : (
                            t("bidNow")
                          )}
                        </Button>
                      )}
                      <AuthBtn
                        onClick={() => user && likeAuction(user.uid, auctionItem.id, user.email)}
                        className="auctionItem-details-page-follow-btn"
                      >
                        <FontAwesomeIcon icon={userLiked ? faHeartCircleCheck : faHeart} />
                        <p>{userLiked ? t("liked") : t("like")}</p>
                      </AuthBtn>
                      <AuthBtn onClick={() => setReportModal(true)} className="auctionItem-details-report-btn">
                        <FontAwesomeIcon icon={faExclamationTriangle} /><p>{t("report")}</p>
                      </AuthBtn>
                      {timeRemaining <= 0 && auctionItem.status === "pending_payment" && auctionItem?.highestBid > auctionItem?.auctionReservePrice && auctionItem?.lastBidderId === userData?.uid ? (
                        <Button onClick={payNowHandler} className="auctionItem-details-page-btn" style={{ marginTop: "10px" }}>PAY NOW</Button>
                      ) : null}
                      {timeRemaining <= 0 && (auctionItem?.highestBid < auctionItem.auctionReservePrice || !auctionItem.bidHistory) && (
                        <Button style={{ marginTop: ".5rem" }} onClick={() => setContactModal(true)} className="auctionItem-details-page-btn"><p>Contact us</p></Button>
                      )}
                    </div>
                  ) : (
                    <div className="btn-container"><Button className="auctionItem-details-page-btn" onClick={showLoginModalHandler}>Login</Button></div>
                  )}
                </div>
              </motion.div>
            </div>
            <motion.div variants={childVariants} className="div-of-description">
              {userLanguage === "it" || currentLanguage === "it" ? (
                <div className="description-content ql-editor">
                  <h3 className="h3-des">{t("description")}</h3>
                  <div dangerouslySetInnerHTML={{ __html: auctionItem.descriptionItalian }} />
                </div>
              ) : (
                <div className="description-content ql-editor">
                  <h3 className="h3-des">{t("description")}</h3>
                  <div dangerouslySetInnerHTML={{ __html: auctionItem.descriptionEnglish }} />
                </div>
              )}
              <TagsColors tags={auctionItem.tags} />
            </motion.div>
          </div>
        </div>
        <AnimatePresence>
          {showBidNow && (
            <BidNowModal
              onClose={() => setBidNow(false)}
              auctionItem={auctionItem}
              bidAmount={bidAmount}
              showLoader={showLoader}
              handleSubmit={submitHandler}
              optionalBidAmount={optionalBidAmount}
              quickBids={quickBids}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {showBidsModal && (
            <>
              <Overlay onClose={() => setShowBidModal(false)} />
              <BidsModal bids={auctionItem.bidHistory} onClose={() => setShowBidModal(false)} />
            </>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {showReportModal && (
            <>
              <Overlay onClose={() => setReportModal(false)} />
              <Modal
                onCancel={() => setReportModal(false)}
                onAgree={() => { setReportModal(false); setShowBidReportModal(true); }}
                cancelText="Cancel"
                successText="Continue"
                icon={faExclamationTriangle}
              >
                <h2>Report</h2>
                <p>Are you sure you want to report <span style={{ fontWeight: "bold" }}>{`"${auctionItem.auctionTitle}"`}</span> auction</p>
              </Modal>
            </>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {showContactModal && (
            <>
              <Overlay onClose={() => setContactModal(false)} />
              <Modal
                onCancel={() => setContactModal(false)}
                onAgree={() => { setContactModal(false); setShowBidContactModal(true); }}
                cancelText="Cancel"
                successText="Continue"
                icon={faExclamationTriangle}
              >
                <h2>Contact</h2>
                <p>Are you sure you want to contact us for <span style={{ fontWeight: "bold" }}>{`"${auctionItem.auctionTitle}"`}</span> auction</p>
              </Modal>
            </>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {showCompleteInfoModal && (
            <>
              <Overlay onClose={() => setShowCompleteInfoModal(false)} />
              <Modal
                onCancel={() => setShowCompleteInfoModal(false)}
                onAgree={() => { setShowCompleteInfoModal(false); navigate("/profile"); }}
                cancelText="Cancel"
                successText="Profile"
                icon={faExclamationTriangle}
              >
                <h2>Complete Profile</h2>
                <p>Please complete your personal info in your profile. Such as Payment Method, Address, First Name, Last Name and Nickname</p>
              </Modal>
            </>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {showBidReportModal && (
            <>
              <Overlay onClose={() => setShowBidReportModal(false)} />
              <ReportModal>
                <ReportAuctionForm auction={auctionItem} user={userData} setModal={setShowBidReportModal} />
              </ReportModal>
            </>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {showBidContactModal && (
            <>
              <Overlay onClose={() => setShowBidContactModal(false)} />
              <ReportModal>
                <EditAuctionRequestForm auction={auctionItem} auctionId={auctionItem.id} setModal={setShowBidContactModal} contactUs={true} collectionName="contactUsMessages" />
              </ReportModal>
            </>
          )}
        </AnimatePresence>
      </motion.div>
      <h2 className="similarItem">Similar Item</h2>
      <SimilarItems filter={auctionItem.category} />
    </>
  );
};

export default AuctionItemDetailPage;

const checkUserFieldsCompleted = async (user, setShowCompleteInfoModal, setBidNow) => {
  if (hasCompletedInformation(user)) setBidNow(true);
  else setShowCompleteInfoModal(true);
};

const sendFirstTimeBidNotification = async (user, metaData, auctionImageTag) => {
  const link = `https://join.bidandgo.it/product/en/${metaData.auctionTitleEn}/${metaData.auctionId}`;
  try {
    fetchAndSendEmailTemplate("For First Bid Of User", user, `${FIREBASE_URL}sendEmail`, link, metaData, auctionImageTag);
  } catch (error) {}
};

const sendOutbidNotification = async (user, metaData, auctionImageTag) => {
  const link = `https://join.bidandgo.it/product/en/${metaData.auctionTitleEn}/${metaData.auctionId}`;
  try {
    fetchAndSendEmailTemplate("For User Out Bid", user, `${FIREBASE_URL}/sendEmail`, link, metaData, auctionImageTag);
  } catch (error) {}
};

const sendHighestBidNotification = async (user, metaData, auctionImageTag) => {
  const link = `https://join.bidandgo.it/product/en/${metaData.auctionTitleEn}/${metaData.auctionId}`;
  try {
    fetchAndSendEmailTemplate("For User Bid", user, `${FIREBASE_URL}/sendEmail`, link, metaData, auctionImageTag);
  } catch (error) {}
};

const checkBidAndSendNotification = async (
  user,
  bidAmount,
  bidHistory,
  lastBidderEmail,
  lastBidderUid,
  metaData,
  auctionImage
) => {
  const auctionImageTag = `<img src='${auctionImage}' alt='Auction Item' style="width: auto; height: auto; max-height: 400px; object-fit: contain; margin: 0 auto; display: block;" />`;
  try {
    if (bidHistory.length === 0 || (bidHistory.length === 1 && bidHistory[0].userEmail === user.email)) {
      sendFirstTimeBidNotification(user, metaData, auctionImageTag);
    }
    const userBid = bidHistory.find((bid) => bid.userEmail === user.email);
    const sortedBidHistory = [...bidHistory].sort((a, b) => b.bidAmount - a.bidAmount);
    const outBid = sortedBidHistory[1]?.userEmail !== user.email;
    if (outBid && lastBidderEmail && lastBidderUid) {
      const userDocRef = doc(db, "users", sortedBidHistory[1].userId);
      const lastBidUserDoc = await getDoc(userDocRef);
      const lastBidUserData = lastBidUserDoc.data();
      const u = {
        email: lastBidderEmail,
        uid: lastBidderUid,
        firstName: lastBidUserData?.firstName,
        lastName: lastBidUserData?.lastName,
        nickname: lastBidUserData?.nickname,
      };
      sendOutbidNotification(u, metaData, auctionImageTag);
    }
    if (!userBid) {
      sendFirstTimeBidNotification(user, metaData, auctionImageTag);
    } else {
      const highestBidAmount = sortedBidHistory[0].bidAmount;
      if (bidAmount === highestBidAmount) sendHighestBidNotification(user, metaData, auctionImageTag);
    }
  } catch (error) {
    console.error("Error checking bid and sending notification:", error);
  }
};

const SimilarItems = ({ filter }) => {
  const { state, getAllAuctions } = useContext(AuctionContext);
  useEffect(() => {
    getAllAuctions();
  }, []);
  return <div><Slider auctions={state.allAuctions} hideHeading={true} filterFor={filter} /></div>;
};