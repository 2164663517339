import React, { useState } from 'react';

const DataDeletion = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://us-central1-auction-99162.cloudfunctions.net/requestDeletion', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      if (response.ok) {
        setMessage('A confirmation email has been sent to your email address.');
        setEmail('');
      } else {
        setMessage('There was an error processing your request.');
      }
    } catch (error) {
      setMessage('There was an error processing your request.');
    }
  };

  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1>Privacy Policy</h1>
      <h2>Data Deletion Instructions</h2>
      <p>
        To request deletion of your data, please enter your email address below. You will receive a confirmation email with a link to confirm the deletion. Once confirmed, your data will be removed from our systems, including the "users" collection and Firebase Authentication.
      </p>
      <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
          style={{ padding: '10px', width: '250px', marginRight: '10px' }}
        />
        <button type="submit" style={{ padding: '10px 20px' }}>
          Request Deletion
        </button>
      </form>
      {message && <p style={{ marginTop: '10px' }}>{message}</p>}
    </div>
  );
};

export default DataDeletion;