import ReactDOM from "react-dom";
import BidNowCard from "./cards/BidNowCard";
import AddessCard from "./cards/AddessCard";
import PaymentCard from "./cards/PaymentCard";
import { FormProvider, useForm } from "react-hook-form";
import closeIcon from "../assets/icons/cancel.svg";
import "../styles/bidnowModalStyles.css";
import Button from "./Button";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { auth, db } from "../config/firebaseSetup";
import { doc, getDoc } from "firebase/firestore";
import Loading from "./Loader";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEuro } from "@fortawesome/free-solid-svg-icons";
import euro from "../assets/icons/euro.svg";
import { motion } from "framer-motion";

const BidNowModal = ({
  onClose,
  auctionItem,
  highestBidAmount,
  handleSubmit,
  bidAmount,
  showLoader,
  optionalBidAmount,
  quickBids,
  lastBidAmount,
}) => {
  const dateString = auctionItem.endDate;
  const [daysDifference, setDaysDifference] = useState(null);

  useEffect(() => {
    const date = new Date(dateString);
    const currentDate = new Date(new Date().getTime() + 1 * 60 * 60 * 1000); // Adjust current time to UTC+1
    const differenceInMilliseconds = date - currentDate;

    let timeDifference;
    if (differenceInMilliseconds < 0) {
      // If the difference is negative, calculate the time difference instead
      const timeDifferenceInMilliseconds = Math.abs(differenceInMilliseconds);
      const hours = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60));
      const minutes = Math.floor(
        (timeDifferenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor(
        (timeDifferenceInMilliseconds % (1000 * 60)) / 1000
      );
      timeDifference = `${hours}H, ${minutes}M`;
      setDaysDifference(timeDifference);
    } else {
      // If the difference is positive, calculate the difference in days
      const days = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
      timeDifference = `${days} days`;
      setDaysDifference(timeDifference);
    }
  }, [dateString]);

  const currentUser = auth.currentUser;

  const methods = useForm({
    defaultValues: async () => {
      try {
        // const userDocRef = doc(db, "users", currentUser.uid);
        // const docSnap = await getDoc(userDocRef);
        // if (docSnap.exists()) {
        return {
          "bid-amount": parseInt(bidAmount),
          "bid-amount-optional":
            auctionItem.highestBid >= optionalBidAmount ? 0 : optionalBidAmount,
        };
        // } else {
        // console.log("No such document!");
        // return {};
        // }
      } catch (error) {
        console.error("Error getting user document:", error);
        return {};
      }
    },
  });
  const submitHandler = methods.handleSubmit(async (formData) => {
    handleSubmit(formData);
  });

  return ReactDOM.createPortal(
    <motion.div
      className="bidNow-modal"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      {showLoader && <Loading />}
      <div onClick={onClose} className="close-icon-bidnow">
        <img src={closeIcon} alt="" width={"40px"} />
      </div>
      <div className="bidnow-modal-grid">
        <FormProvider {...methods}>
          <form
            className="bid-modal-form"
            action=""
            onSubmit={(e) => e.preventDefault()}
          >
            <BidNowCard
              quickBids={quickBids}
              minValue={
                // Use the original starting price if no bids exist
                auctionItem.bidHistory?.length > 0
                  ? Number(auctionItem.highestBid + 1)
                  : Number(auctionItem.auctionStartingPrice)
              }
            />
            {/* <AddessCard /> */}
            {/* <div className="card-form payment-card">
              <div className="card-inputs ">
                <div className="payment-form">
                  <PaymentCard
                    showinfo={false}
                    submitHandler={submitHandler}
                    btnText={"Bid"}
                  />
                </div>
              </div>
            </div> */}
            <Button onClick={submitHandler}>BID</Button>
          </form>
        </FormProvider>
        <div className="bid-now-item">
          <div className="img-container-bidnow">
            <div className="image-overlay">
              <img src={auctionItem.images[0].fileimage} alt="" />
            </div>
            <h2 className="bid-now-item-title">{auctionItem.title}</h2>
          </div>
          <div className="bid-now-item-details">
            <div className="">
              {auctionItem?.bidHistory?.length > 0 ? (
                <div className="user-bid-a">
                  <p>Last Bid</p>
                  <img src={euro} alt="" width={"20px"} height={"20px"} />
                  <p>{auctionItem?.highestBid}</p>
                </div>
              ) : (
                <div className="user-bid-a">
                  <p>Starting Price</p>
                  <img src={euro} alt="" width={"20px"} height={"20px"} />
                  <p>{auctionItem.auctionStartingPrice}</p>
                </div>
              )}
            </div>
            {/* <p>
              <span className="spanOne-bit-now">{daysDifference}</span>
              <span>Time left</span>
            </p> */}
          </div>
        </div>
      </div>
    </motion.div>,
    document.querySelector("#bidnowModal")
  );
};

export default BidNowModal;
