import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Button,
  Badge,
  Group,
  Image,
  Container,
  Paper,
  ActionIcon,
  Title,
  Grid,
  Stack,
  Divider,
  Tooltip,
  Skeleton,
  Transition,
  Flex,
  Avatar,
  TextInput,
  Progress,
  Collapse,
  ThemeIcon,
  Modal,
  rem,
  Tabs,
  Timeline,
  Loader,
  Card,
  ScrollArea,
} from '@mantine/core';
import { 
  IconArrowLeft, 
  IconUser, 
  IconHeart, 
  IconHeartFilled,
  IconMessageCircle, 
  IconAlertTriangle,
  IconClock,
  IconInfoCircle,
  IconTags,
  IconShield,
  IconGavel,
  IconStar,
  IconStarFilled,
  IconHistory,
  IconCar,
  IconUsers,
  IconListDetails,
  IconAward,
  IconPlus,
  IconChevronDown,
  IconChevronUp,
  IconShare,
  IconPhoto,
  IconZoomIn,
  IconChevronRight,
} from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';

function NavigationBar() {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${theme.spacing.md} ${theme.spacing.xl}`,
        background: 'linear-gradient(135deg, #4338ca, #3b82f6)',
        color: 'white',
        boxShadow: theme.shadows.lg,
        position: 'sticky',
        top: 0,
        zIndex: 1000,
      })}
    >
      <Group spacing="md">
        <ActionIcon variant="light" color="blue" radius="xl" onClick={() => console.log('Go back')}>
          <IconArrowLeft size={22} />
        </ActionIcon>
        <Text size="lg" weight={700} color="white">
          Auction Item
        </Text>
      </Group>
      
      <Group spacing="md">
        <ActionIcon variant="light" color="blue" radius="xl" onClick={() => console.log('Share')}>
          <IconShare size={22} />
        </ActionIcon>
        <ActionIcon variant="light" color="blue" radius="xl" onClick={() => console.log('User profile')}>
          <IconUser size={22} />
        </ActionIcon>
      </Group>
    </Box>
  );
}

function ItemHeader({ liked, setLiked }) {
  return (
    <Paper shadow="sm" radius="md" p="xl" mt="md" withBorder>
      <Group position="apart">
        <div>
          <Group spacing="xs">
            <Title order={1} color="indigo.9">
              Drag Car 2
            </Title>
            <Badge color="red" size="lg" radius="sm" variant="filled">HOT</Badge>
          </Group>
          <Group spacing="xs" mt={5}>
            <Badge color="blue" size="md" radius="sm" variant="outline">VINTAGE</Badge>
            <Badge color="teal" size="md" radius="sm" variant="outline">RACING</Badge>
            <Badge color="grape" size="md" radius="sm" variant="outline">COLLECTOR</Badge>
          </Group>
        </div>
        <Group>
          <ActionIcon 
            size="xl" 
            radius="xl" 
            color="red" 
            variant={liked ? "filled" : "light"}
            onClick={() => setLiked(!liked)}
          >
            {liked ? <IconHeartFilled size={24} /> : <IconHeart size={24} />}
          </ActionIcon>
          <Text weight={700} size="xl" color="dimmed">
            (-€60)
          </Text>
        </Group>
      </Group>
      <Group mt="md">
        <Group spacing="xs">
          <ThemeIcon color="blue" size="md" radius="xl">
            <IconGavel size={16} />
          </ThemeIcon>
          <Text size="sm" color="dimmed">
            7 Bids
          </Text>
        </Group>
        
        <Group spacing="xs">
          <ThemeIcon color="violet" size="md" radius="xl">
            <IconUsers size={16} />
          </ThemeIcon>
          <Text size="sm" color="dimmed">
            23 Watchers
          </Text>
        </Group>
        
        <Group spacing="xs">
          <ThemeIcon color="teal" size="md" radius="xl">
            <IconStar size={16} />
          </ThemeIcon>
          <Text size="sm" color="dimmed">
            4.8/5 (16 Ratings)
          </Text>
        </Group>
      </Group>
    </Paper>
  );
}

function ImageGallery() {
  const [activeImage, setActiveImage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [opened, { open, close }] = useDisclosure(false);
  
  const images = [
    "https://picsum.photos/seed/car1/800/500",
    "https://picsum.photos/seed/car2/800/500",
    "https://picsum.photos/seed/car3/800/500",
    "https://picsum.photos/seed/car4/800/500",
  ];

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Modal opened={opened} onClose={close} size="xl" centered padding={0}>
        <Image
          src={images[activeImage]}
          alt={`Drag Car 2 Image ${activeImage + 1}`}
          height={600}
          fit="contain"
        />
      </Modal>

      <Paper shadow="md" radius="lg" p="md" withBorder>
        <Box
          sx={(theme) => ({
            position: 'relative',
            width: '100%',
            height: 400,
            borderRadius: theme.radius.md,
            overflow: 'hidden',
            boxShadow: theme.shadows.md,
            cursor: 'pointer',
          })}
          onClick={open}
        >
          <Transition mounted={!loading} transition="fade" duration={400}>
            {(styles) => (
              <div style={styles}>
                <Image
                  src={images[activeImage]}
                  alt={`Drag Car 2 Image ${activeImage + 1}`}
                  height={400}
                  fit="cover"
                />
                <ActionIcon
                  sx={(theme) => ({
                    position: 'absolute',
                    top: theme.spacing.md,
                    right: theme.spacing.md,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    }
                  })}
                  size="lg"
                  radius="xl"
                  color="gray"
                  variant="filled"
                >
                  <IconZoomIn color="white" />
                </ActionIcon>
              </div>
            )}
          </Transition>
          {loading && <Skeleton height={400} radius="md" animate />}
        </Box>

        <ScrollArea mt="md">
          <Group position="center" spacing="xs" noWrap>
            {images.map((img, index) => (
              <Box
                key={index}
                sx={(theme) => ({
                  width: 80,
                  height: 60,
                  borderRadius: theme.radius.sm,
                  overflow: 'hidden',
                  border: activeImage === index ? `2px solid ${theme.colors.blue[6]}` : '2px solid transparent',
                  opacity: activeImage === index ? 1 : 0.7,
                  cursor: 'pointer',
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    opacity: 1,
                  },
                })}
                onClick={() => setActiveImage(index)}
              >
                <Image src={img} height={60} width={80} fit="cover" />
              </Box>
            ))}
          </Group>
        </ScrollArea>
      </Paper>
    </>
  );
}

function CountdownTimer() {
  const calculateTimeLeft = () => {
    const difference = +new Date('2025-04-05T01:00:00') - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <Card shadow="sm" p="md" radius="md" withBorder>
      <Group position="apart" align="center">
        <Group spacing="xs">
          <ThemeIcon color="indigo" size="md" radius="xl">
            <IconClock size={16} />
          </ThemeIcon>
          <Text weight={600} size="sm">Auction Ends:</Text>
        </Group>
        <Text size="xs" color="dimmed">
          April 5, 2025 at 1:00 AM (Asia/Karachi)
        </Text>
      </Group>
      
      <Divider my="sm" />
      
      <Group grow spacing="xs">
        {Object.keys(timeLeft).length ? (
          ['days', 'hours', 'minutes', 'seconds'].map((interval) => (
            <Paper
              key={interval}
              shadow="xs"
              p="md"
              radius="md"
              sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.blue[0],
                textAlign: 'center',
                transition: 'transform 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-2px)',
                },
              })}
            >
              <Text size="xl" weight={700} color="blue">
                {timeLeft[interval]}
              </Text>
              <Text size="xs" color="dimmed" transform="uppercase">
                {interval}
              </Text>
            </Paper>
          ))
        ) : (
          <Text color="red" weight={700} align="center">Auction Ended</Text>
        )}
      </Group>
    </Card>
  );
}

function BidSection() {
  const [customBid, setCustomBid] = useState('');
  const [selectedBid, setSelectedBid] = useState(null);
  
  const suggestedBids = [
    { value: 85, label: '€85' },
    { value: 90, label: '€90' },
    { value: 95, label: '€95' },
    { value: 100, label: '€100' },
  ];

  return (
    <Card shadow="sm" p="md" radius="md" mt="md" withBorder>
      <Group position="apart">
        <Group spacing="xs">
          <ThemeIcon color="teal" size="md" radius="xl">
            <IconGavel size={16} />
          </ThemeIcon>
          <Text weight={600} size="sm">Bidding Info</Text>
        </Group>
      </Group>
      
      <Divider my="sm" />
      
      <Group position="apart" mb="md">
        <Text size="xl" weight={700} color="blue">
          Current Bid: €80
        </Text>
        <Badge color="indigo" size="lg">
          7 Bids
        </Badge>
      </Group>

      <Progress 
        value={80} 
        size="sm" 
        radius="xl" 
        color="teal" 
        mb="md"
        label={`€80 of €100 reserve`}
        styles={{ label: { color: 'white', fontWeight: 700 } }}
      />
      
      <Text size="sm" weight={500} mb="xs">
        Select a quick bid:
      </Text>
      
      <Group spacing="xs" mb="md">
        {suggestedBids.map((bid) => (
          <Button
            key={bid.value}
            variant={selectedBid === bid.value ? "filled" : "outline"}
            color="indigo"
            radius="md"
            onClick={() => setSelectedBid(bid.value)}
            sx={(theme) => ({
              transition: 'all 0.2s ease',
              '&:hover': {
                backgroundColor: theme.colors.indigo[1],
                transform: 'translateY(-2px)',
              },
            })}
          >
            {bid.label}
          </Button>
        ))}
      </Group>
      
      <TextInput
        label="Enter custom bid amount"
        placeholder="€ Enter amount"
        value={customBid}
        onChange={(e) => {
          setCustomBid(e.target.value);
          setSelectedBid(null);
        }}
        mb="md"
        rightSection={<Text color="dimmed" size="sm">EUR</Text>}
      />
      
      <Button
        fullWidth
        size="lg"
        radius="md"
        color="teal"
        leftIcon={<IconGavel size={20} />}
        sx={(theme) => ({
          background: 'linear-gradient(135deg, #0d9488, #0891b2)',
          height: 50,
          boxShadow: theme.shadows.md,
          transition: 'all 0.3s ease',
          '&:hover': {
            background: 'linear-gradient(135deg, #0f766e, #0e7490)',
            transform: 'translateY(-2px)',
          },
        })}
      >
        PLACE BID NOW
      </Button>
    </Card>
  );
}

function SellerInfo() {
  return (
    <Card shadow="sm" p="md" radius="md" mt="md" withBorder>
      <Group position="apart">
        <Group spacing="xs">
          <ThemeIcon color="violet" size="md" radius="xl">
            <IconUser size={16} />
          </ThemeIcon>
          <Text weight={600} size="sm">Seller Information</Text>
        </Group>
      </Group>
      
      <Divider my="sm" />
      
      <Group position="left" spacing="md">
        <Avatar
          size={60}
          radius="xl"
          src="https://picsum.photos/seed/seller/200"
          alt="Seller avatar"
        />
        <div>
          <Text size="md" weight={600}>John Collector</Text>
          <Group spacing="xs">
            <ThemeIcon color="yellow" size="xs" radius="xl">
              <IconStarFilled size={12} />
            </ThemeIcon>
            <Text size="xs" color="dimmed">4.9/5 (153 ratings)</Text>
          </Group>
          <Group spacing="xs" mt={5}>
            <Badge color="gray" size="sm" radius="sm" variant="dot">Member since 2020</Badge>
            <Badge color="green" size="sm" radius="sm" variant="dot">Verified Seller</Badge>
          </Group>
        </div>
      </Group>
      
      <Button
        fullWidth
        variant="outline"
        color="blue"
        leftIcon={<IconMessageCircle size={16} />}
        mt="md"
        radius="md"
      >
        Contact Seller
      </Button>
    </Card>
  );
}

function ItemDetailsTab() {
  const [detailsOpened, { toggle: toggleDetails }] = useDisclosure(true);
  const [specificationOpened, { toggle: toggleSpecification }] = useDisclosure(false);
  const [historyOpened, { toggle: toggleHistory }] = useDisclosure(false);

  return (
    <Paper shadow="md" radius="lg" p="xl" mt="xl" withBorder>
      <Stack spacing="md">
        {/* Description Section */}
        <Group position="apart" onClick={toggleDetails} sx={{ cursor: 'pointer' }}>
          <Group spacing="xs">
            <ThemeIcon color="blue" size="lg" radius="xl">
              <IconInfoCircle size={20} />
            </ThemeIcon>
            <Title order={3} color="dark">
              Description
            </Title>
          </Group>
          {detailsOpened ? <IconChevronUp size={20} /> : <IconChevronDown size={20} />}
        </Group>
        
        <Collapse in={detailsOpened}>
          <Text sx={{ lineHeight: 1.7 }} size="md">
            The Drag Car 2 is a vintage masterpiece, perfect for collectors and enthusiasts alike. 
            With its sleek design and powerful performance, this car is ready to dominate the tracks. 
            This particular model has been meticulously maintained and restored to near-original condition, 
            with upgrades to the engine and suspension for improved performance.
            <br /><br />
            Features include a custom paint job, reinforced chassis, and competition-grade tires. 
            The interior has been preserved in its original style, making this a true collector's item 
            that combines historical significance with modern performance capabilities.
          </Text>
          
          <Group spacing="xs" mt="md">
            <Badge color="blue" size="lg" radius="md" variant="outline">VINTAGE</Badge>
            <Badge color="teal" size="lg" radius="md" variant="outline">RACING</Badge>
            <Badge color="grape" size="lg" radius="md" variant="outline">COLLECTOR</Badge>
            <Badge color="orange" size="lg" radius="md" variant="outline">LIMITED EDITION</Badge>
            <Badge color="indigo" size="lg" radius="md" variant="outline">RESTORED</Badge>
          </Group>
        </Collapse>
        
        <Divider />
        
        {/* Specifications Section */}
        <Group position="apart" onClick={toggleSpecification} sx={{ cursor: 'pointer' }}>
          <Group spacing="xs">
            <ThemeIcon color="indigo" size="lg" radius="xl">
              <IconListDetails size={20} />
            </ThemeIcon>
            <Title order={3} color="dark">
              Specifications
            </Title>
          </Group>
          {specificationOpened ? <IconChevronUp size={20} /> : <IconChevronDown size={20} />}
        </Group>
        
        <Collapse in={specificationOpened}>
          <Grid>
            <Grid.Col span={6}>
              <Stack spacing="xs">
                <Group position="apart">
                  <Text weight={600}>Year</Text>
                  <Text>1969</Text>
                </Group>
                <Group position="apart">
                  <Text weight={600}>Make</Text>
                  <Text>Classic Motors</Text>
                </Group>
                <Group position="apart">
                  <Text weight={600}>Model</Text>
                  <Text>Drag Runner S2</Text>
                </Group>
                <Group position="apart">
                  <Text weight={600}>Engine</Text>
                  <Text>V8, 450 HP</Text>
                </Group>
              </Stack>
            </Grid.Col>
            
            <Grid.Col span={6}>
              <Stack spacing="xs">
                <Group position="apart">
                  <Text weight={600}>Condition</Text>
                  <Text>Restored</Text>
                </Group>
                <Group position="apart">
                  <Text weight={600}>Mileage</Text>
                  <Text>12,543 miles</Text>
                </Group>
                <Group position="apart">
                  <Text weight={600}>Color</Text>
                  <Text>Racing Red</Text>
                </Group>
                <Group position="apart">
                  <Text weight={600}>Location</Text>
                  <Text>Berlin, Germany</Text>
                </Group>
              </Stack>
            </Grid.Col>
          </Grid>
        </Collapse>
        
        <Divider />
        
        {/* History Section */}
        <Group position="apart" onClick={toggleHistory} sx={{ cursor: 'pointer' }}>
          <Group spacing="xs">
            <ThemeIcon color="grape" size="lg" radius="xl">
              <IconHistory size={20} />
            </ThemeIcon>
            <Title order={3} color="dark">
              Item History
            </Title>
          </Group>
          {historyOpened ? <IconChevronUp size={20} /> : <IconChevronDown size={20} />}
        </Group>
        
        <Collapse in={historyOpened}>
          <Timeline active={3} bulletSize={24} lineWidth={2}>
            <Timeline.Item 
              bullet={<IconCar size={12} />} 
              title="Original Production"
              lineVariant="solid"
            >
              <Text color="dimmed" size="sm">Manufactured in 1969 by Classic Motors</Text>
              <Text size="xs" mt={4}>February 12, 1969</Text>
            </Timeline.Item>
            
            <Timeline.Item 
              bullet={<IconAward size={12} />} 
              title="Racing History"
              lineVariant="solid"
            >
              <Text color="dimmed" size="sm">Won the National Drag Racing Championship</Text>
              <Text size="xs" mt={4}>August 5, 1972</Text>
            </Timeline.Item>
            
            <Timeline.Item 
              bullet={<IconUser size={12} />} 
              title="Restoration Completed"
              lineVariant="solid"
            >
              <Text color="dimmed" size="sm">Full restoration by renowned mechanic James Wilson</Text>
              <Text size="xs" mt={4}>June 17, 2023</Text>
            </Timeline.Item>
            
            <Timeline.Item 
              bullet={<IconGavel size={12} />} 
              title="Current Auction"
            >
              <Text color="dimmed" size="sm">Listed for auction by John Collector</Text>
              <Text size="xs" mt={4}>March 15, 2025</Text>
            </Timeline.Item>
          </Timeline>
        </Collapse>
      </Stack>
    </Paper>
  );
}

function BidHistory() {
  const bidHistory = [
    { user: 'CarEnthusiast88', amount: '€80', time: '2 hours ago' },
    { user: 'VintageCollector', amount: '€75', time: '5 hours ago' },
    { user: 'RacingFan45', amount: '€70', time: '7 hours ago' },
    { user: 'ClassicMotors', amount: '€65', time: '9 hours ago' },
    { user: 'SpeedDemon', amount: '€60', time: '12 hours ago' },
    { user: 'AuctionPro', amount: '€55', time: '1 day ago' },
    { user: 'FirstBidder', amount: '€50', time: '1 day ago' },
  ];

  return (
    <Paper shadow="md" radius="lg" p="xl" mt="md" withBorder>
      <Group spacing="xs" mb="md">
        <ThemeIcon color="blue" size="lg" radius="xl">
          <IconHistory size={20} />
        </ThemeIcon>
        <Title order={3} color="dark">
          Bid History
        </Title>
      </Group>
      
      <ScrollArea style={{ height: 300 }}>
        <Stack spacing="xs">
          {bidHistory.map((bid, index) => (
            <Paper 
              key={index} 
              p="md" 
              radius="md" 
              withBorder
              sx={(theme) => ({
                backgroundColor: index === 0 ? theme.colors.blue[0] : 'white',
                transition: 'transform 0.2s ease',
                '&:hover': {
                  transform: 'translateX(5px)',
                  backgroundColor: theme.colors.gray[0],
                },
              })}
            >
              <Group position="apart">
                <Group spacing="sm">
                  <Avatar color={index === 0 ? "blue" : "gray"} radius="xl">
                    {bid.user.charAt(0)}
                  </Avatar>
                  <div>
                    <Text weight={600}>{bid.user}</Text>
                    <Text size="xs" color="dimmed">{bid.time}</Text>
                  </div>
                </Group>
                <Text weight={700} size="lg" color={index === 0 ? "blue" : "dark"}>
                  {bid.amount}
                </Text>
              </Group>
            </Paper>
          ))}
        </Stack>
      </ScrollArea>
    </Paper>
  );
}

function RecommendedItems() {
  const items = [
    { id: 1, title: "Vintage Race Car", price: "€120", image: "https://picsum.photos/seed/rec1/200" },
    { id: 2, title: "Classic Convertible", price: "€95", image: "https://picsum.photos/seed/rec2/200" },
    { id: 3, title: "Sports Coupe", price: "€110", image: "https://picsum.photos/seed/rec3/200" },
    { id: 4, title: "Collector's Edition", price: "€150", image: "https://picsum.photos/seed/rec4/200" },
  ];

  return (
    <Paper shadow="md" radius="lg" p="xl" mt="xl" withBorder>
      <Group position="apart" mb="lg">
        <Group spacing="xs">
          <ThemeIcon color="teal" size="lg" radius="xl">
            <IconStar size={20} />
          </ThemeIcon>
          <Title order={3} color="dark">
            Similar Items
          </Title>
        </Group>
        <Button variant="subtle" color="blue" rightSection={<IconChevronRight size={16} />}>
          View All
        </Button>
      </Group>
      
      <Grid>
        {items.map((item) => (
          <Grid.Col key={item.id} span={{ base: 12, xs: 6, sm: 4, md: 3 }}>
            <Card
              p="md"
              radius="md"
              withBorder
              sx={(theme) => ({
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: theme.shadows.md,
                },
              })}
            >
              <Card.Section>
                <Image
                  src={item.image}
                  height={150}
                  alt={item.title}
                />
              </Card.Section>
              
              <Text weight={500} size="md" mt="md">
                {item.title}
              </Text>
              
              <Group position="apart" mt="xs">
                <Text weight={700} color="blue">
                  {item.price}
                </Text>
                <Button compact variant="subtle" color="blue">
                  View
                </Button>
              </Group>
            </Card>
          </Grid.Col>
        ))}
      </Grid>
    </Paper>
  );
}

function FloatingActions() {
  return (
    <Box sx={{ position: 'fixed', bottom: 24, right: 24, zIndex: 900 }}>
      <Group spacing="sm">
        <Tooltip label="Report Issue" position="left" withArrow>
          <ActionIcon 
            color="yellow" 
            size="xl" 
            radius="xl" 
            variant="filled"
            sx={(theme) => ({
              boxShadow: theme.shadows.md,
              transition: 'transform 0.2s ease',
              '&:hover': {
                transform: 'scale(1.1)',
              },
            })}
          >
            <IconAlertTriangle size={24} />
          </ActionIcon>
        </Tooltip>
        
        <Tooltip label="Contact Support" position="left" withArrow>
          <ActionIcon 
            color="blue" 
            size="xl" 
            radius="xl" 
            variant="filled"
            sx={(theme) => ({
              boxShadow: theme.shadows.md,
              transition: 'transform 0.2s ease',
              '&:hover': {
                transform: 'scale(1.1)',
              },
            })}
          >
            <IconMessageCircle size={24} />
          </ActionIcon>
        </Tooltip>
      </Group>
    </Box>
  );
}

function AuctionItemDetailTestPage() {
  const [liked, setLiked] = useState(false);
  const [activeTab, setActiveTab] = useState('details');

  return (
    <Box sx={(theme) => ({ backgroundColor: theme.colors.gray[0], minHeight: '100vh' })}>
      <NavigationBar />
      
      <Container size="lg" px={{ base: 'xs', sm: 'xl' }} py="xl">
        <ItemHeader liked={liked} setLiked={setLiked} />
        
        <Grid gutter="xl" mt="md">
          <Grid.Col span={{ base: 12, md: 8 }}>
            <ImageGallery />
            
            <Tabs
              value={activeTab}
              onTabChange={setActiveTab}
              radius="xs"
              mt="xl"
            >
              <Tabs.List grow>
                <Tabs.Tab 
                  value="details" 
                  icon={<IconInfoCircle size={16} />}
                >
                  Details
                </Tabs.Tab>
                <Tabs.Tab 
                  value="bids" 
                  icon={<IconHistory size={16} />}
                >
                  Bid History
                </Tabs.Tab>
                <Tabs.Tab 
                  value="similar" 
                  icon={<IconStar size={16} />}
                >
                  Similar Items
                </Tabs.Tab>
              </Tabs.List>
              
              <Box mt="md">
                {activeTab === 'details' && <ItemDetailsTab />}
                {activeTab === 'bids' && <BidHistory />}
                {activeTab === 'similar' && <RecommendedItems />}
              </Box>
            </Tabs>
          </Grid.Col>
          
          <Grid.Col span={{ base: 12, md: 4 }}>
            <Stack spacing="md">
              <CountdownTimer />
              <BidSection />
              <SellerInfo />
            </Stack>
          </Grid.Col>
        </Grid>
      </Container>
      
      <FloatingActions />
    </Box>
  );
}

export default AuctionItemDetailTestPage;
